import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { languages } from '../i18n';

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // URL'den dil kodunu al
  const urlLang = location.pathname.split('/')[1];

  React.useEffect(() => {
    // URL'deki dil geçerli ve mevcut dilden farklıysa
    if (urlLang && Object.keys(languages).includes(urlLang) && i18n.language !== urlLang) {
      // Dili güncelle
      i18n.changeLanguage(urlLang).then(() => {
        document.documentElement.lang = urlLang;
      });
    }
  }, [urlLang, i18n]);

  React.useEffect(() => {
    // Geçerli bir dil kodu yoksa yönlendirme yap
    if (!urlLang || !Object.keys(languages).includes(urlLang)) {
      // Tarayıcı dilini veya varsayılan olarak İngilizce'yi kullan
      const browserLang = navigator.language.split('-')[0].toLowerCase();
      const defaultLang = Object.keys(languages).includes(browserLang) ? browserLang : 'en';
      
      // Mevcut yolu al (başındaki / olmadan)
      const currentPath = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname;
      
      // Yeni URL'ye yönlendir
      navigate(`/${defaultLang}/${currentPath}`, { replace: true });
    }
  }, [urlLang, location.pathname, navigate]);

  return <Outlet />;
}